import React from "react";
import styled from "styled-components";
import { localStorageKey } from "../../../utils/requestHandler";
import { FixedNavbar } from "../common";
import "../../../assets/styles/header.css";

export const FixedNavbarTemplate = ({ children }) => {
  return (
    window.localStorage.getItem(localStorageKey) && (
      <FixedNavbarContainer>
        <FixedNavbarBorderRadiusContainer id="headerLL">
          <FixedNavbar />
          {children}
        </FixedNavbarBorderRadiusContainer>
      </FixedNavbarContainer>
    )
  );
};

export const FixedNavbarContainer = styled.div`
  background-color: rgb(61, 45, 83);
`;

export const FixedNavbarBorderRadiusContainer = styled.div`
  width: calc(100vw - 272px);
  height: calc(100% - 70px);
  background-color: white;
  border-radius: 20px;
  padding-top: 70px;
`;
