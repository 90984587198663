import React, { useState } from "react";
import Select from "@atlaskit/select";
import { Box, Spacing, Button, Label } from "../ui/atoms";
import { Avatar } from "../ui/molecules/Avatar";
import { Rounded } from "../ui/molecules/Rounded";
import AddIcon from "@atlaskit/icon/glyph/add";
import { B400 } from "@atlaskit/theme/colors";
import { siblingTypes } from "../../pages";
import { ModalDialog } from "../ui/common";
import styled from 'styled-components'

export const SiblingsItem = ({
  sibling,
  onAdd,
  onDelete,
  siblingType = "",
  visibleRemove = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [defaultSiblingType, setDefaultSiblingType] = useState(siblingTypes[0]);

  return (
    <Spacing m={{ l: "00px", b: "22px" }}>
      <Box d="flex" align="center">
        <Rounded
          onClick={() => window.open(`${sibling?.id}`)}
          onDelete={onDelete}
          visibleRemove={visibleRemove}
          content={
            <>
              <Avatar
                // size="small"
                name={`${sibling?.first_name} ${sibling?.last_name}`}
                isChild
              />
              <Box style={{display:'flex', flexDirection: 'column', marginLeft: 5}}>
                <StyledLabel>
                {siblingType &&
                    `${
                      siblingTypes.find((item) => item.value === siblingType)
                        ?.label
                    }`}
                  {sibling?.sex === 'male' ? ' Brother' : ' Sister'}
                </StyledLabel>
                  {sibling?.first_name} {sibling?.last_name}
              </Box>
              {onAdd ? (
                <>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsModalOpen(true);
                    }}
                    appearance="subtle"
                    spacing="none"
                    style={{
                      borderRadius: 30,
                      marginLeft: 15,
                      padding: "3px 5px",
                    }}
                  >
                    <AddIcon size="small" primaryColor={B400} />
                  </Button>
                </>
              ) : null}
            </>
          }
        />
      </Box>
      <ModalDialog
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        heading="Select sibling type"
        positiveLabel="Select"
        onClick={() => onAdd(defaultSiblingType.value)}
        width="small"
        body={
          <>
            <Spacing m={{ t: "10px" }}>
              <Select
                className="multi-select"
                classNamePrefix="react-select"
                menuPortalTarget={document.body}
                value={defaultSiblingType}
                onFocus={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setDefaultSiblingType(e);
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                options={siblingTypes}
                placeholder="Select SiblingType"
              />
            </Spacing>
          </>
        }
      />
    </Spacing>
  );
};

const StyledLabel = styled(Label)`
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #6b778c;
`;