import styled from "styled-components";

export const ComponentWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

const Wrapper = styled.div`
  display: block;
  width: calc(100% - 80px);
  min-height: 100vh;
  padding: 28px 40px;
  background-color: #F3F5F6;
`;
