export const ACTIONS = {
  VISIT: "VISIT",
  VISIT_ONE: "VISIT_ONE",
  EDIT: "EDIT",
  ADD: "ADD",
  REMOVE: "REMOVE",
  ASSIGN_USER: "ASSIGN_USER",
  SET_REMINDER: "SET_REMINDER",
  REMOVE_ASSIGNED_USER: "REMOVE_ASSIGNED_USER",
  CONFIRM: "CONFIRM",
  PLACE: "PLACE",
  DISQUALIFY: "DISQUALIFY",
  ACCEPT: "ACCEPT",
  REJECT: "REJECT",
  ADD_TASK_ID: "ADD_TASK_ID",
};
