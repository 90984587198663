import * as AtlassianButtonModule from "@atlaskit/button";
import { useEffect } from "react";
import styled from "styled-components";
import { Box } from "./Box";
import styles from '../../../index.css'

export const Button = (props) => {
  const Icon = props.iconBefore;
  if (props.appearance === "link")
    return (
      <StyledBox d="flex" style={{whiteSpace: "nowrap"}}>
        {props.iconBefore}
        <Link {...props} />
      </StyledBox>
    );
  return <AtlassianButton id={props.appearance === "warning" ? 'warning-button' : '' } {...props} />;
};

const StyledBox = styled(Box)`
  color: #ff6f61;
  vertical-align: middle;
  &:hover,
  &:focus,
  &.active {
    color: rgb(143, 98, 173);
    // background-color: #5B4677;
    --icon-accent-color: #0052cc;
    outline: none;
  }
`;

const Link = styled.button`
  color: #ff6f61;
  background: none;
  vertical-align: middle;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 15px;
  &:hover,
  &:focus,
  &.active {
    color: rgb(143, 98, 173);
    // background-color: #5B4677;
    --icon-accent-color: #0052cc;
    outline: none;
  }
`;

const AtlassianButton = styled(AtlassianButtonModule.default)`
  @keyframes forceYellow {
    from {
      color: white;
    }
    to {
      color: white;
    }
  }
  background-color: ${(props) => {
    switch (props.appearance) {
      case "primary":
        return "#5B4677 !important";
      // case "link":
      // case "subtle-link":
      case "warning":
        return "#FF6F61 !important";
    }
  }};
  color: ${(props) => {
    switch (props.appearance) {
      case "primary":
        return "#FFFFFF !important";
      // case "link":
      // case "subtle-link":
      case "warning":
        return "white !important";
    }
  }};
  animation: ${(props) => {
    switch (props.appearance) {
      case "warning":
        return "1s linear infinite forceYellow";
    }
  }};
  border-radius: ${(props) => {
    switch (props.appearance) {
      case "warning":
        return "20px !important";
    }
  }};
`;
