import { ButtonGroup } from "@atlaskit/button";
import PersonIcon from "@atlaskit/icon/glyph/person";
import CommentIcon from "@atlaskit/icon/glyph/comment";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import EditorDoneIcon from "@atlaskit/icon/glyph/editor/done";
import EmailIcon from "@atlaskit/icon/glyph/email";
import NotificationIcon from "@atlaskit/icon/glyph/notification-direct";
import Tooltip from "@atlaskit/tooltip";
import styled from "styled-components";
import { ACTIONS } from "../accessControl/actions";
import Can from "../accessControl/Can";
import { updateConnectionRequest } from "../api/childContact";
import { DisqualifyTooltip } from "../components/ChildProfile/tabs/Connections/DisqualifyTooltip";
import { Box, Spacing, Button } from "../components/ui/atoms";
import { FitScore } from "../components/ui/molecules";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import moment from "moment";


import { Avatar } from "../components/ui/molecules/Avatar";
import { CONNECTIONS } from "../helpers";
export const SmallText = styled.div`
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  color: #172b4d;
`;

const StyledButton = styled(Button)`
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  color: #172b4d;
`;

export const confirmedConnectionRows = (
  data,
  setPending,
  openModal,
  setCurrentConnection,
  fetchConnections,
  setIsAddModalOpen,
  setIsDisModalOpen,
  setIsPlaceModalOpen
) => {
  const existPlaced = data.find((c) => c.status === "placed");
  return data
    .filter((item) => item?.is_confirmed)
    .sort(
      (item1, item2) =>
        (item1?.status === "disqualified" - item2?.status) === "disqualified"
    )
    .sort(
      (item1, item2) =>
        (item2?.status === "placed" - item1?.status) === "placed"
    )
    .map((item, index) => {
      const onPlacementUpdate = (is_placed = true) => {
        if (existPlaced && is_placed) {
          return;
        }
        setPending();
        updateConnectionRequest(item.id, {
          status: is_placed ? "placed" : "",
          placed_date: null,
        }).then(() => fetchConnections());
      };

      const onEdit = () => {
        setCurrentConnection(item);
        setIsAddModalOpen(true);
      };

      return (
        item &&
        item.contact && {
          index: index,
          cells: [
            {
              key: "full_name",
              content: (
                <Box d="flex" direction="column">
                  <Box d="flex" align="center">
                    <Avatar
                      name={`${item.contact.first_name} ${item.contact.last_name}`}
                      size="large"
                      ratio={0.4}
                      color="#3D2D53"
                    />
                    <Box ml="8px">
                      <Spacing m={{ l: "5px" }}>
                        <SmallText>{item.contact?.relationship}</SmallText>
                      </Spacing>
                      <Box d="flex" align="center">
                        <Button
                          appearance="link"
                          spacing="none"
                          onClick={() => openModal("main", item)}
                        >{`${item.contact.first_name} ${item.contact.last_name}`}</Button>
                      </Box>
                      {item.status === "disqualified" && (
                        <Tooltip
                          content={
                            <DisqualifyTooltip
                              contact={item?.contact}
                              reason={item?.disqualify_reason}
                            />
                          }
                        >
                          <Box d="flex" style={{ cursor: "pointer" }}>
                            <SmallText>(DISQUALIFIED)</SmallText>
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                </Box>
              ),
            },
            {
              key: "relationship",
              content: (
                <Box>
                  <Box d="flex" mr="2px">
                    {item.contact.birthday 
                    ? (
                      <EditorDoneIcon />
                    ) : (
                      <EditorCloseIcon />
                    )}
                    <SmallText style={{ marginLeft: "6px" }}>DOB {item?.contact?.birthday ? moment(item?.contact?.birthday).utcOffset('+0500').format("MM/DD/YYYY") : ''} </SmallText>
                  </Box>
                  <Box d="flex" mr="2px">
                    {item.contact.communications.some(
                      (e) =>
                        e.communication_type === "email" &&
                        e.is_current === true
                    ) ? (
                      <EditorDoneIcon />
                    ) : (
                      <EditorCloseIcon />
                    )}
                    <SmallText style={{ marginLeft: "6px" }}>Email</SmallText>
                  </Box>
                  <Box d="flex" mr="4px">
                    {item.contact.communications.some(
                      (e) =>
                        e.communication_type === "phone" &&
                        e.is_current === true
                    ) ? (
                      <EditorDoneIcon />
                    ) : (
                      <EditorCloseIcon />
                    )}
                    <SmallText style={{ marginLeft: "6px" }}>Phone</SmallText>
                  </Box>
                  <Box d="flex" mr="6px">
                    {item.contact.communications.some(
                      (e) =>
                        e.communication_type === "address" &&
                        e.is_current === true
                    ) ? (
                      <EditorDoneIcon />
                    ) : (
                      <EditorCloseIcon />
                    )}
                    <SmallText style={{ marginLeft: "6px" }}>Address</SmallText>
                  </Box>
                  
                </Box>
              ),
            },
            {
              key: "engagement",
              content: (
                <Box>
                  <Button
                    appearance="link"
                    spacing="none"
                    onClick={() => openModal("contact", item)}
                    style={{ marginRight: "17px" }}
                  >
                    <Box d="flex" align="center">
                      <PersonIcon />
                      <Spacing m={{ r: "4px", l: "3px" }}>
                        Contact Information
                      </Spacing>
                    </Box>
                  </Button>
                  <Button
                    appearance="link"
                    spacing="none"
                    onClick={() => openModal("templates", item)}
                    style={{ marginRight: "17px" }}
                  >
                    <Box d="flex" align="center">
                      <EmailIcon />
                      <Spacing m={{ r: "4px" }}>
                        ({item.templates_size}) Engagements
                      </Spacing>
                    </Box>
                  </Button>
                  <Button
                    appearance="link"
                    spacing="none"
                    onClick={() => openModal("alerts", item)}
                    style={{ marginRight: "17px" }}
                  >
                    <Box d="flex" align="center">
                      <NotificationIcon />
                      <Spacing m={{ r: "4px" }}>
                        ({item.alerts_size}) Link alerts
                      </Spacing>
                    </Box>
                  </Button>
                  <Button
                    appearance="link"
                    spacing="none"
                    onClick={() => openModal("comments", item)}
                    style={{ marginRight: "17px" }}
                  >
                    <Box d="flex" align="center">
                      <CommentIcon />
                      <Spacing m={{ r: "4px" }}>
                        ({item.comments_size}) Comments
                      </Spacing>
                    </Box>
                  </Button>
                </Box>
              ),
            },
            {
              key: "link_score",
              content: (
                <Box
                  d="flex"
                  direction="column"
                  justify="flex-start"
                  align="center"
                >
                  <Box d="flex" justify="center" align="center">
                    Link score
                  </Box>
                  <Box d="flex" justify="center" align="center">
                    <StarFilledIcon />{" "}
                    {item.link_score_overall > 0
                      ? (5 * item.link_score_overall) / 100
                      : 0}
                  </Box>
                </Box>
              ),
            },
            {
              key: "actions",
              content: (
                <Can
                  perform={`${CONNECTIONS}:${ACTIONS.EDIT}`}
                  yes={() => (
                    <div align="center">
                      {item.status === "placed" ? (
                        <ButtonGroup>
                          <Button a onClick={onEdit}>
                            Edit
                          </Button>
                          <Button onClick={() => onPlacementUpdate(false)}>
                            Remove Placement
                          </Button>
                        </ButtonGroup>
                      ) : item.status === "disqualified" ? (
                        <ButtonGroup>
                          <Button onClick={onEdit}>Edit</Button>
                        </ButtonGroup>
                      ) : (
                        <Box d="flex" direction="column">
                          <Button appearance="warning" onClick={onEdit}>
                            Edit
                          </Button>
                          <Spacing  m={{t: "7px"}}/>
                          {!existPlaced && (
                            <Button
                              appearance="warning"
                              onClick={() => {
                                setIsPlaceModalOpen(true);
                                setCurrentConnection(item);
                              }}
                            >
                              Place
                            </Button>
                          )}
                        </Box>
                      )}
                    </div>
                  )}
                />
              ),
            },
          ],
        }
      );
    });
};
