import React, { useState } from "react";
import styled from "styled-components";
import { localStorageKey } from "../../../utils/requestHandler";
import "../../../assets/styles/sidebar.css";
import MenuIcon from "@atlaskit/icon/glyph/menu";
import { Sidebar } from "../common";


export const SidebarTemplate = ({ sidebar }) => {

  const [show, setShow] = useState(false);

  const toggleSidebar = () => {
    console.log('TOGGLE SIDEBAR!!', show);
    setShow(!show);
  }
  

  return (
    window.localStorage.getItem(localStorageKey) && (<>
      <div id="menuToggle" onClick={() => toggleSidebar()}>
        <MenuIcon size="large" />
      </div>
      <SidebarContainer id="sidebarContainerLL" className={show ? "show" : ""}><Sidebar toggle={toggleSidebar}></Sidebar></SidebarContainer>
      </>
    )
  );
};

export const SidebarContainer = styled.div`
  width: 240px;
  flex: 0 0 240px;
  position: sticky;
  top: 0;
  padding: 0px 16px;
  height: 100vh;
  background-color: #3D2D53;
`;
