import React from "react";
import styled from "styled-components";
import { getLocalStorageUser } from "../../../context/auth/authProvider";
import { Avatar } from "../molecules/Avatar";
import { Box } from '../atoms'

export const SidebarUser = () => {
  const user = getLocalStorageUser();
  return (
    user && (
      <SidebarUserContainer>
        <Avatar color='white' fgColor='#616161' name={`${user.first_name} ${user.last_name}`} />
        <Box className="d-sm-none">
          <UserNameText>
            {user?.first_name && user?.first_name[0].toUpperCase() + user?.first_name.substring(1)}{" "}
            {user?.last_name && user?.last_name[0].toUpperCase() + user?.last_name.substring(1)}
          </UserNameText>
          <EmailText>
            {user?.email}
        </EmailText>
        </Box>
      </SidebarUserContainer>
    )
  );
};

const SidebarUserContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 17px;
  align-items: center;
`;

const UserNameText = styled.div`
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  margin-left: 14px;
`;

const EmailText = styled.div`
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: white;
  display: flex;
  align-items: center;
  margin-left: 14px;
`

