import React, { memo } from "react";
import ImagePlacholder from "../../../assets/image_placeholder.svg";
import Log from "../../../assets/image.png";
const InnerLogo = ({ link, height = 160, isAcme = false }) => {
  return (
    <img
      style={{
        height,
        paddingTop: 20,
        paddingBottom: 20,
        marginTop: 30,
        marginBottom: 30,
      }}
      alt="Logo"
      src={(isAcme ? Log : link) || ImagePlacholder}
    />
  );
};

export const Logo = memo(InnerLogo);
