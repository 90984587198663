import Select from "@atlaskit/select";
import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { updateUserRequest } from "../../../api/user/userRequest";
import { useAuth } from "../../../context/auth/authContext";
import { Box, Logo, Spacing } from "../atoms";

const NavbarInner = () => {
  const { user, fetchMe } = useAuth();
  const history = useHistory();
  const setCurrentOrganization = async (data) => {
    console.log(data);
    localStorage.setItem("organizationName", data?.organization?.name);
    await updateUserRequest(user.id, {
      user: {
        organization_id: data.organization_id,
        role: data.role,
      },
    });
    await fetchMe();
    history.push("/");
  };

  return (
    <Box
      id="headerNavBarLL"
      d="flex"
      direction="column"
      justify="space-between"
      align="space-between"
      h="70px"
      style={{
        top: 0,
        left: 272,
        width: "calc(100vw - 272px)",
        position: "fixed",
        overflowY: "visible",
        boxShadow: "1px 7px 10px 5px rgba(0,0,0,0.24)",
        WebkitBoxShadow: "1px 7px 10px -1px rgba(0,0,0,0.24)",
        backgroundColor: "#3D2D53",
        zIndex: 2,
      }}
    >
      <Box
        d="flex"
        align="center"
        justify="flex-end"
        h="70px"
        style={{
          backgroundColor: "white",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <Logo
          height={48}
          link={user?.selectedOrganization?.value.organization.logo}
        />
        <Spacing style={{ width: 200 }} m={{ l: "15px", r: "15px" }}>
          <Select
            onChange={({ value }) =>
              value.organization_id !== user.organization_id &&
              setCurrentOrganization(value)
            }
            value={user?.selectedOrganization}
            className="single-select"
            classNamePrefix="react-select"
            options={user?.user_organizations?.map((userOrganizations) => ({
              value: userOrganizations,
              label: userOrganizations?.organization?.name,
            }))}
          />
        </Spacing>
      </Box>
    </Box>
  );
};

export const FixedNavbar = memo(NavbarInner);
