import { ButtonGroup } from "@atlaskit/button";
import AttachmentIcon from "@atlaskit/icon/glyph/attachment";
import EmailIcon from "@atlaskit/icon/glyph/email";
import PersonIcon from "@atlaskit/icon/glyph/person";
import CommentIcon from "@atlaskit/icon/glyph/comment";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import EditorDoneIcon from "@atlaskit/icon/glyph/editor/done";
import NotificationIcon from "@atlaskit/icon/glyph/notification-direct";
import React from "react";
import { ACTIONS } from "../accessControl/actions";
import Can from "../accessControl/Can";
import { SmallText } from "../components/ChildProfile/tabs/Connections";
import { Box, Label, Spacing, Button } from "../components/ui/atoms";
import { Avatar } from "../components/ui/molecules/Avatar";
import { CONNECTIONS } from "../helpers";
import moment from "moment";


export const possibleConnectionRows = (
  data,
  openModal,
  setCurrentConnection,
  setIsConfirmModalOpen,
  setIsAddModalOpen
) =>
  data
    .filter((item) => item && !item.is_confirmed)
    .map(
      (item, index) =>
        item &&
        item.contact && {
          key: index,
          cells: [
            {
              key: "full_name",
              content: (
                <Box d="flex" align="center">
                  <Avatar
                    name={`${item.contact.first_name} ${item.contact.last_name}`}
                    size="large"
                    ratio={0.4}
                    color="#3D2D53"
                  />

                  <Box direction="flex" align="baseline">
                    <Spacing m={{ l: "15px" }}>
                      <SmallText>{item.contact?.relationship}</SmallText>
                    </Spacing>
                    <Button
                      appearance="link"
                      spacing="none"
                      style={{ marginLeft: "8px" }}
                      onClick={() => openModal("main", item)}
                    >
                      {`${item.contact.first_name} ${item.contact.last_name}`}
                    </Button>
                    {item.linked_connection && (
                      <Label
                        style={{ marginLeft: 8 }}
                      >{`${item.linked_connection.first_name} ${item.linked_connection.last_name}`}</Label>
                    )}
                  </Box>
                </Box>
              ),
            },
            {
              key: "relationship",
              content: (
                <Box>
                  <Box d="flex" mr="2px">
                    {item.contact.birthday 
                    ? (
                      <EditorDoneIcon />
                    ) : (
                      <EditorCloseIcon />
                    )}
                    <SmallText style={{ marginLeft: "6px" }}>DOB {item?.contact?.birthday ? moment(item?.contact?.birthday).utcOffset('+0500').format("MM/DD/YYYY") : ''} </SmallText>
                  </Box>
                  <Box d="flex" mr="2px">
                    {item.contact.communications.some(
                      (e) =>
                        e.communication_type === "email" &&
                        e.is_current === true
                    ) ? (
                      <EditorDoneIcon />
                    ) : (
                      <EditorCloseIcon />
                    )}
                    <SmallText style={{ marginLeft: "6px" }}>Email</SmallText>
                  </Box>
                  <Box d="flex" mr="4px">
                    {item.contact.communications.some(
                      (e) =>
                        e.communication_type === "phone" &&
                        e.is_current === true
                    ) ? (
                      <EditorDoneIcon />
                    ) : (
                      <EditorCloseIcon />
                    )}
                    <SmallText style={{ marginLeft: "6px" }}>Phone</SmallText>
                  </Box>
                  <Box d="flex" mr="6px">
                    {item.contact.communications.some(
                      (e) =>
                        e.communication_type === "address" &&
                        e.is_current === true
                    ) ? (
                      <EditorDoneIcon />
                    ) : (
                      <EditorCloseIcon />
                    )}
                    <SmallText style={{ marginLeft: "6px" }}>Address</SmallText>
                  </Box>
                  
                </Box>
              ),
            },
            {
              key: "engagement",
              content: (
                <Box>
                  
                  <Button
                    appearance="link"
                    spacing="none"
                    onClick={() => openModal("contact", item)}
                    style={{ marginRight: "17px" }}
                  >
                    <Box d="flex" align="center">
                      <PersonIcon />
                      <Spacing m={{ r: "4px" }}>
                        Contact Information
                      </Spacing>
                    </Box>
                  </Button>
                  <Button
                    appearance="link"
                    spacing="none"
                    onClick={() => openModal("templates", item)}
                    style={{ marginRight: "17px" }}
                  >
                    <Box d="flex" align="center">
                      <EmailIcon />
                      <Spacing m={{ r: "4px" }}>
                        ({item.templates_size}) Engagements
                      </Spacing>
                    </Box>
                  </Button>
                  <Button
                    appearance="link"
                    spacing="none"
                    onClick={() => openModal("alerts", item)}
                    style={{ marginRight: "17px" }}
                  >
                    <Box d="flex" align="center">
                      <NotificationIcon />
                      <Spacing m={{ r: "4px" }}>
                        ({item.alerts_size}) Link alerts
                      </Spacing>
                    </Box>
                  </Button>
                  <Button
                    appearance="link"
                    spacing="none"
                    onClick={() => openModal("comments", item)}
                    style={{ marginRight: "17px" }}
                  >
                    <Box d="flex" align="center">
                      <CommentIcon />
                      <Spacing m={{ r: "4px" }}>
                        ({item.comments_size}) Comments
                      </Spacing>
                    </Box>
                  </Button>

                  
                </Box>
              ),
            },
            {
              key: "from_whom",
              content: (
                <Box
                  d="flex"
                  direction="column"
                  justify="flex-start"
                  align="center"
                >
                  <Box d="flex" justify="center" align="center">
                    <SmallText>possibly connected to:</SmallText>
                  </Box>
                  <Box d="flex" justify="center" align="center">
                    {item?.contact?.from_whom}
                  </Box>
                </Box>
              ),
            },
            {
              key: "actions",
              content: (
                <Can
                  perform={`${CONNECTIONS}:${ACTIONS.EDIT}`}
                  yes={() => (
                    <div align="center">
                      <Box d="flex" direction="column">
                        <Button
                          appearance="warning"
                          onClick={() => {
                            setCurrentConnection(item);
                            setIsAddModalOpen(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Spacing m={{ t: "7px" }} />

                        <Button
                          appearance="warning"
                          onClick={() => {
                            setCurrentConnection(item);
                            setIsConfirmModalOpen(true);
                          }}
                        >
                          Link
                        </Button>
                      </Box>
                    </div>
                  )}
                />
              ),
            },
          ],
        }
    );
