export const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
        width: "100%"
      }}
    >
      <h4>Page not found :(</h4>
    </div>
  )
}
